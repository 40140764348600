<template>
  <head>
    <title>Error - My CSC</title>
  </head>

  <div class="p-4 grid gap-6">
    <div class="relative grid">
      <c-csc-logo width="256" class="justify-self-center" />

      <div class="bg-white -mt-8 text-2xl text-center z-10">My CSC</div>
    </div>

    <p class="text-center">
      Oh no! We are experiencing technical difficulties.
    </p>

    <c-button @click="handleError">Try again</c-button>
  </div>
</template>

<script lang="ts" setup>
import type { NuxtError } from '#app';

defineProps<{
  error?: NuxtError;
}>();

const handleError = () => {
  clearError();

  window.location.reload();
};
</script>
